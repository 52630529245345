<template>
  <div class="type_area">
    <Bread :direct="direct" :bgc="bgc"></Bread>
  </div>
</template>

<script>
import Bread from '@/components/Bread'
export default {
    components: {
        Bread
    },
 data() {
        return {
            direct: ['首页', '公告'],
            bgc: '#f3f3f3',
           
        }
    },
}
</script>

<style>

</style>